import { getMessaging, isSupported, getToken } from 'firebase/messaging';

import { upsertDevicePushToken } from '../api/user';
import { FIREBASE_API } from '../config-global';
import { Platform, UpsertDeviceTokenDTO, User } from '../types';
import { getDeviceType } from './device-type';

async function registerServiceWorker() {
  const isProdEnvironment = typeof window !== 'undefined' && window.location.host === 'trybe.fm';

  // Service Worker only works with HTTPS so we disable it for localhost
  if (!isProdEnvironment) {
    return;
  }

  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.info('Service Worker registered with scope:', registration.scope);
    } catch (error) {
      console.error('Service Worker registration failed:', error);
      throw error;
    }
  } else {
    throw new Error('Service Worker not supported in this browser');
  }
}

export async function handleDeviceToken(user: User) {
  // User has disabled all notifications
  if (user?.notificationPreferences?.disableAll) {
    return;
  }

  try {
    const isBrowserSupported = await isSupported();
    if (!isBrowserSupported) {
      return;
    }

    await registerServiceWorker();

    const messaging = getMessaging();

    const status = await Notification.requestPermission();

    if (status === 'denied') {
      return;
    }

    const fcmToken = await getToken(messaging, { vapidKey: FIREBASE_API.vapidKey });
    const isPWA = JSON.parse(localStorage.getItem('isPWA') || 'false');

    const payload: UpsertDeviceTokenDTO = {
      isPWA,
      platform: Platform.TRYBE,
      token: fcmToken,
      deviceType: getDeviceType(),
    };

    await upsertDevicePushToken(payload);
  } catch (e) {
    console.error(e);
  }
}
