import { createBrowserClient } from '@supabase/ssr';
import { Session, SupabaseClient } from '@supabase/supabase-js';
import { useRenderCount } from '@uidotdev/usehooks';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { setSession } from 'src/auth/context/jwt/utils';

let globalInstance: SupabaseClient | null = null;

export function useSupabase() {
  const [session, setSupaSession] = useState<Session | null>(null);
  const renderCount = useRenderCount();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  // Add a ref to track initial session handling
  const isInitialSessionHandled = useRef(false);

  const supabase = useMemo(() => {
    if (globalInstance) {
      return globalInstance;
    }

    if (!process.env.NEXT_PUBLIC_SUPABASE_URL || !process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY) {
      throw new Error('Missing Supabase environment variables');
    }

    try {
      globalInstance = createBrowserClient(
        process.env.NEXT_PUBLIC_SUPABASE_URL,
        process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY
      );
      return globalInstance;
    } catch (err) {
      console.error('Failed to create Supabase client:', err);
      setError(err instanceof Error ? err : new Error('Failed to initialize Supabase'));
      throw err;
    }
  }, []);

  const fetchUserAndSession = useCallback(async () => {
    if (isInitialSessionHandled.current) return;

    try {
      // First, try to get the session
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

      if (sessionError) {
        console.warn('No existing session:', sessionError);
        setLoading(false);
        return;
      }

      // If a session exists, proceed to get user
      if (sessionData?.session) {
        const { data: userData, error: userError } = await supabase.auth.getUser();

        if (userError) {
          console.warn('Failed to fetch user:', userError);
          setError(userError);
        } else if (userData.user) {
          const { access_token, refresh_token } = sessionData.session;
          console.log('userData,', userData);
          setSupaSession(sessionData.session);
          posthog.identify(userData?.user?.email);

          // Optional: Set session using tokens
          await supabase.auth.setSession({ access_token, refresh_token });
          await setSession(access_token);
        }
      }

      isInitialSessionHandled.current = true;
    } catch (err) {
      console.error('Unexpected error during user and session fetch:', err);
      setError(err instanceof Error ? err : new Error('Unexpected authentication error'));
    } finally {
      setLoading(false);
    }
  }, [supabase]);

  useEffect(() => {
    const { data: authSubscription } = supabase.auth.onAuthStateChange((event, newSession) => {
      // Prevent handling INITIAL_SESSION multiple times
      if (event === 'INITIAL_SESSION' && isInitialSessionHandled.current) {
        return;
      }

      if (newSession) {
        setSupaSession(newSession);

        // Mark initial session as handled
        if (event === 'INITIAL_SESSION') {
          isInitialSessionHandled.current = true;
        }
      } else {
        setSupaSession(null);
      }
    });

    // Fetch user and session data only if renderCount is less than 3
    if (renderCount < 3) {
      fetchUserAndSession();
    }

    return () => {
      authSubscription.subscription.unsubscribe();
    };
  }, [fetchUserAndSession, renderCount, supabase]);

  return {
    session,
    supabase,
    loading,
    error,
  };
}
