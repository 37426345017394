import { Button } from '@mui/material';

import Iconify from '../../../components/iconify';
import { useSupabase } from '../../../hooks/use-supabase';
import { SocialLoginProvider } from '../../../types';

export default function FacebookLogin() {
  const { supabase } = useSupabase();
  let baseUrl: string;

  if (typeof window !== 'undefined') {
    // This code runs only in the browser
    baseUrl = window.location.origin;
  } else {
    // Fallback to a default base URL in case of server-side rendering
    baseUrl = 'http://localhost:8082';
  }

  const onClick = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'facebook',
      options: {
        redirectTo: `${baseUrl}/auth/callback`,
        queryParams: {
          socialProvider: SocialLoginProvider.Facebook,
          redirectTo: `${baseUrl}/auth/callback`,
        },
      },
    });

    // console.log({ data, error });
  };
  return (
    <Button
      fullWidth
      variant="contained"
      style={{
        backgroundColor: '#000000',
        color: '#fff',
      }}
      size="large"
      onClick={onClick}
      startIcon={<Iconify icon="akar-icons:facebook-fill" color="white" />}
    >
      Continue with Facebook
    </Button>
  );
}
