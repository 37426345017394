export enum Interest {
  Cryptocurrency = 'cryptocurrency',
  Party = 'party',
  Music = 'music',
  Festival = 'festival',
  Netflix = 'netflix',
  Engineering = 'engineering',
  Technology = 'technology',
  Drawing = 'drawing',
  Printing = 'printing',
  Radio = 'radio',
  Scrapbook = 'scrapbook',
  Acting = 'acting',
  BatonTwirling = 'batonTwirling',
  BoardGames = 'boardGames',
  ShootingRange = 'shootingRange',
  BookRestoration = 'bookRestoration',
  Cabaret = 'cabaret',
  Calligraphy = 'calligraphy',
  CandleMaking = 'candleMaking',
  ComputerProgramming = 'computerProgramming',
  CoffeeRoasting = 'coffeeRoasting',
  Cooking = 'cooking',
  Colouring = 'colouring',
  Cosplaying = 'cosplaying',
  Couponing = 'couponing',
  CreativeWriting = 'creativeWriting',
  Crocheting = 'crocheting',
  Cryptography = 'cryptography',
  Dance = 'dance',
  DigitalArts = 'digitalArts',
  Drama = 'drama',
  DIY = 'diy',
  Electronics = 'electronics',
  Embroidery = 'embroidery',
  Fashion = 'fashion',
  FlowerArranging = 'flowerArranging',
  ForeignLanguageLearning = 'foreignLanguageLearning',
  Gaming = 'gaming',
  TabletopGames = 'tabletopGames',
  RoleplayingGames = 'roleplayingGames',
  Gambling = 'gambling',
  Genealogy = 'genealogy',
  Glassblowing = 'glassblowing',
  Gunsmithing = 'gunsmithing',
  Homebrewing = 'homebrewing',
  Iceskating = 'iceskating',
  JewelryMaking = 'jewelryMaking',
  JigsawPuzzles = 'jigsawPuzzles',
  Juggling = 'juggling',
  Knapping = 'knapping',
  Knitting = 'knitting',
  Kabaddi = 'kabaddi',
  KnifeMaking = 'knifeMaking',
  Lacemaking = 'lacemaking',
  Lapidary = 'lapidary',
  LeatherCrafting = 'leatherCrafting',
  LegoBuilding = 'legoBuilding',
  Lockpicking = 'lockpicking',
  Machining = 'machining',
  Macrame = 'macrame',
  Metalworking = 'metalworking',
  Magic = 'magic',
  ModelBuilding = 'modelBuilding',
  Origami = 'origami',
  Painting = 'painting',
  PlayingMusicalInstruments = 'playingMusicalInstruments',
  Pet = 'pet',
  Poi = 'poi',
  Pottery = 'pottery',
  Puzzles = 'puzzles',
  Quilting = 'quilting',
  Reading = 'reading',
  Scrapbooking = 'scrapbooking',
  Sculpting = 'sculpting',
  Sewing = 'sewing',
  Singing = 'singing',
  Sketching = 'sketching',
  Soapmaking = 'soapmaking',
  Sports = 'sports',
  StandUp = 'standUp',
  Sudoku = 'sudoku',
  TableTennis = 'tableTennis',
  Taxidermy = 'taxidermy',
  VideoGaming = 'videoGaming',
  WatchingMovies = 'watchingMovies',
  WebSurfing = 'webSurfing',
  Whittling = 'whittling',
  WoodCarving = 'woodCarving',
  Woodworking = 'woodworking',
  WorldBuilding = 'worldBuilding',
  Writing = 'writing',
  Yoga = 'yoga',
  YoYoing = 'yoYoing',
  AirSports = 'airSports',
  Archery = 'archery',
  Astronomy = 'astronomy',
  Backpacking = 'backpacking',
  BaseJumping = 'baseJumping',
  Baseball = 'baseball',
  Basketball = 'basketball',
  Beekeeping = 'beekeeping',
  BirdWatching = 'birdWatching',
  Blacksmithing = 'blacksmithing',
  BoardSports = 'boardSports',
  Bodybuilding = 'bodybuilding',
  BraziliaJiuJitsu = 'braziliaJiuJitsu',
  Community = 'community',
  Cycling = 'cycling',
  Dowsing = 'dowsing',
  Driving = 'driving',
  Fishing = 'fishing',
  Finance = 'finance',
  Investment = 'investment',
  FlagFootball = 'flagFootball',
  Flying = 'flying',
  FlyingDisc = 'flyingDisc',
  Foraging = 'foraging',
  Gardening = 'gardening',
  Geocaching = 'geocaching',
  GhostHunting = 'ghostHunting',
  Graffiti = 'graffiti',
  Handball = 'handball',
  Hiking = 'hiking',
  Hooping = 'hooping',
  HorsebackRiding = 'horsebackRiding',
  Hunting = 'hunting',
  InlineSkating = 'inlineSkating',
  Jogging = 'jogging',
  Kayaking = 'kayaking',
  KiteFlying = 'kiteFlying',
  Kitesurfing = 'kitesurfing',
  Larping = 'larping',
  Letterboxing = 'letterboxing',
  MetalDetecting = 'metalDetecting',
  MotorSports = 'motorSports',
  MountainBiking = 'mountainBiking',
  Mountaineering = 'mountaineering',
  MushroomHunting = 'mushroomHunting',
  Mycology = 'mycology',
  Netball = 'netball',
  NordicSkating = 'nordicSkating',
  Orienteering = 'orienteering',
  Paintball = 'paintball',
  Parkour = 'parkour',
  Photography = 'photography',
  Polo = 'polo',
  Rafting = 'rafting',
  Rappelling = 'rappelling',
  RockClimbing = 'rockClimbing',
  RollerSkating = 'rollerSkating',
  Rugby = 'rugby',
  Running = 'running',
  Sailing = 'sailing',
  SandArt = 'sandArt',
  Scouting = 'scouting',
  ScubaDiving = 'scubaDiving',
  Sculling = 'sculling',
  Rowing = 'rowing',
  Shopping = 'shopping',
  Skateboarding = 'skateboarding',
  Skiing = 'skiing',
  SkimBoarding = 'skimBoarding',
  Skydiving = 'skydiving',
  Slacklining = 'slacklining',
  Snowboarding = 'snowboarding',
  StoneSkipping = 'stoneSkipping',
  Surfing = 'surfing',
  Swimming = 'swimming',
  Taekwondo = 'taekwondo',
  TaiChi = 'taiChi',
  UrbanExploration = 'urbanExploration',
  Vacation = 'vacation',
  VehicleRestoration = 'vehicleRestoration',
  WaterSports = 'waterSports',
}
