/* eslint-disable import/no-cycle */
import { Factor, UserAppMetadata, UserIdentity, UserMetadata } from '@supabase/supabase-js';

import { EventConnectMatch, EventConnectRequest } from '../connect';
import { IEventParticipant, LiveReview, Venue } from '../events';
import { EventTag } from '../events/event-tag';
import { Address } from '../shared';
import { Picture } from '../shared/picture';
import { CurrencyCodes } from './currency';
import { DevicePushToken } from './device.push.token';
import { Interest } from './interest';
import { NotificationPreferences } from './notification-preferences';
import { ITier, SubscriptionStatus, SubscriptionTier } from './subscription';
import { Wallet } from './wallet';

export interface EventFilter {
  selectedTags?: EventTag[];
}

export interface UserPreferences {
  filters?: EventFilter;
  occupation?: string;
  description?: string;
  locale: string;
  currency?: CurrencyCodes;
  interests?: Interest[];
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
}

export interface SendSmsDTO {
  phone?: string;
  email?: string;
}

export interface VerificationCodeDTO {
  phone?: string;
  email?: string;
  code: string;
}

export interface VerificationCodeResponse {
  used: boolean;
  userId?: number;
}

export interface Subscription {
  id: number;
  stripeSubscriptionId: string;
  paymentIntentId: string;
  paymentMethodId: string;
  invoiceId: string;
  orderConfirmationId: string;
  status: SubscriptionStatus;
  tier: SubscriptionTier;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  userId: number;
  user: User;
}

export interface AvatarSettings {
  event: boolean;
  connect: boolean;
}
interface UserProfile {
  description: string;
  interests: string[];
  occupation: string;
}

export interface LoginResponse {
  accessToken: string;
  refreshToken: string;
  user: Pick<User, 'id' | 'firstname' | 'lastname' | 'email' | 'phone' | 'stripeId'>;
}

export interface CreateSocialUserDTO {
  email: string;
  phone: string;
  uid: string;
  fullname?: string;
  picture?: string;
}

export interface ManualLinkingDTO {
  uid: string;
  userId: number;
  phone: string;
}

export interface User {
  id: number;
  firstname: string;
  lastname?: string;
  alias?: string;
  logo?: Picture;
  email: string;
  phone: string;
  latitude: number;
  longitude: number;
  radius: number;
  avatarSettings?: AvatarSettings;
  preferredAddress?: Address;
  birthdate?: string;
  preferences?: UserPreferences;
  stripeId: string;
  profile: UserProfile;
  wallet?: Wallet;
  isVerified: boolean;
  pictures: Picture[];
  gender?: Gender;
  cover?: Picture;
  sessionToken: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  notificationPreferences?: NotificationPreferences;
  submittedEvents?: Event[];
  attendedEvents?: IEventParticipant[];
  venues?: Venue[];
  notifications?: Notification[];
  liveReviews?: LiveReview[];
  subscription?: Subscription;
  requestReceived?: EventConnectRequest[];
  matchesSent?: EventConnectMatch[];
  matchesReceived?: EventConnectMatch[];
  reports?: Report[];
  tier?: ITier;
  devicePushTokens?: DevicePushToken[];
  showAvatar?: boolean;
}

export interface SupabaseUser {
  id: string;
  app_metadata: UserAppMetadata;
  user_metadata: UserMetadata;
  aud: string;
  confirmation_sent_at?: string;
  recovery_sent_at?: string;
  email_change_sent_at?: string;
  new_email?: string;
  new_phone?: string;
  invited_at?: string;
  action_link?: string;
  email?: string;
  phone?: string;
  created_at: string;
  confirmed_at?: string;
  email_confirmed_at?: string;
  phone_confirmed_at?: string;
  last_sign_in_at?: string;
  role?: string;
  updated_at?: string;
  identities?: UserIdentity[];
  is_anonymous?: boolean;
  factors?: Factor[];
}
