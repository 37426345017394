import axios from 'axios';

import { UpsertDeviceTokenDTO, User } from '../types';
import { axiosInstance, endpoints } from '../utils/axios';

export async function exists(email: string): Promise<boolean> {
  const response = await axios.get<boolean>(endpoints.user.exists, { params: { email } });
  return response.data;
}

export async function upsertDevicePushToken(payload: UpsertDeviceTokenDTO) {
  const response = await axiosInstance.post(endpoints.devicePushTokens.upsert, payload);
  return response.data;
}

export async function fetchUserInfos(): Promise<User> {
  const { data } = await axiosInstance.get(endpoints.auth.me);
  return data;
}

export const getChatToken = async (userId: string): Promise<string | null> => {
  try {
    const response = await fetch('/api/generateToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId }),
    });

    if (!response.ok) {
      throw new Error('Failed to fetch token');
    }

    const data = await response.json();
    return data.token;
  } catch (error) {
    console.error('Error fetching chat token:', error);
    return null;
  }
};
