import { create } from 'zustand';

interface UnreadStore {
  unreadCount: number | null;
  setUnreadCount: (count: number) => void;
}

export const useUnreadStore = create<UnreadStore>((set) => ({
  unreadCount: null, // Initial state
  setUnreadCount: (count: number) => set({ unreadCount: count }),
}));
