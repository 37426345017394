export enum Platform {
  TRYBE = 'TRYBE',
  NEST = 'NEST',
}

export interface UpsertDeviceTokenDTO {
  platform: Platform;
  token: string;
  deviceType: string;
  isPWA: boolean;
}

export interface DevicePushToken {
  id: number;
  platform: Platform;
  userId: number;
  token: string;
  deviceType: string;
  browser: string;
  os: string;
  isPWA: boolean;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}
