import { Button } from '@mui/material';

import Iconify from '../../../components/iconify';
import { useSupabase } from '../../../hooks/use-supabase';

export default function GoogleLogin() {
  const { supabase } = useSupabase();

  let baseUrl: string;

  if (typeof window !== 'undefined') {
    // This code runs only in the browser
    baseUrl = window.location.origin;
  } else {
    // Fallback to a default base URL in case of server-side rendering
    baseUrl = 'http://localhost:8082';
  }

  const onClick = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${baseUrl}/auth/callback`,
          queryParams: {
            socialProvider: 'google',
            redirectTo: `${baseUrl}/auth/callback`,
          },
        },
      });
    } catch (err) {
      console.error('Unexpected error:', err);
    }
  };
  return (
    <Button
      fullWidth
      variant="contained"
      style={{
        backgroundColor: '#000000',
        color: '#fff',
      }}
      size="large"
      onClick={onClick}
      startIcon={<Iconify icon="logos:google-icon" />}
    >
      Continue with Google
    </Button>
  );
}
