import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import FacebookLogin from './fb-login';
import GoogleLogin from './google-login';

type SocialLoginProps = {
  reversed?: boolean;
};

export default function SocialLogin({ reversed }: SocialLoginProps) {
  return (
    <Stack justifyContent="center" alignItems="center" gap="16px">
      {!reversed && (
        <Stack gap={1} direction="row" alignItems="center" width="100%">
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body2">Or</Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Stack>
      )}
      <GoogleLogin />
      <FacebookLogin />
      {reversed && (
        <Stack gap={1} direction="row" alignItems="center" width="100%">
          <Divider sx={{ flexGrow: 1 }} />
          <Typography variant="body2">Or</Typography>
          <Divider sx={{ flexGrow: 1 }} />
        </Stack>
      )}
    </Stack>
  );
}
