import {
  CreateSocialUserDTO,
  ManualLinkingDTO,
  SendSmsDTO,
  User,
  VerificationCodeDTO,
  VerificationCodeResponse,
} from '../types';
import { toQueryParams } from '../utils';
import axios, { endpoints } from '../utils/axios';

export async function sendPhoneVerificationCode(
  payload: SendSmsDTO,
  isSocialLogin?: boolean
): Promise<VerificationCodeResponse> {
  if (!payload.phone && !payload.email) {
    throw new Error('Phone or email must be provided to send SMS');
  }

  const response = await axios.post<VerificationCodeResponse>(
    `${endpoints.auth.phoneVerification}?${toQueryParams({ isSocialLogin })}`,
    payload
  );

  return response.data;
}

export async function verifyPhoneCode(
  payload: VerificationCodeDTO,
  isSocialLogin?: boolean
): Promise<void> {
  if (!payload.phone && !payload.email) {
    throw new Error('Phone or email must be provided to send SMS');
  }

  await axios.post(
    `${endpoints.auth.phoneVerifyCode}?${toQueryParams({ isSocialLogin })}`,
    payload
  );
}

export async function socialSignUp(payload: CreateSocialUserDTO): Promise<User> {
  const { data } = await axios.post(endpoints.auth.socialSignUp, payload);
  return data;
}

export async function manualLinking(payload: ManualLinkingDTO): Promise<void> {
  await axios.post(endpoints.auth.manualLinking, payload);
}
