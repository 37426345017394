'use client';

import { LoadingSplashScreen } from 'src/components/loading-screen';

import { AuthContext } from './auth-context';

type AuthConsumerProps = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: AuthConsumerProps) {
  return (
    <AuthContext.Consumer>
      {(auth) => (auth ? <>{children}</> : <LoadingSplashScreen />)}
    </AuthContext.Consumer>
  );
}
