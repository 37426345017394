import Box, { BoxProps } from '@mui/material/Box';
import Link from '@mui/material/Link';
import { forwardRef } from 'react';
import { RouterLink } from 'src/routes/components';
import { usePathname } from 'src/routes/hooks';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}
const blackListPages = ['/avatar/', '/profile/'];

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const pathname = usePathname();

    const isCurrentPageDisableClick = disabledLink || blackListPages.includes(pathname);

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: 'inline-flex',
          ...sx,
        }}
        {...other}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 375 375">
          <defs>
            <clipPath id="ca46314068">
              <path stroke="#fff" d="M49.215 49.215h288v288h-288zm0 0" />
            </clipPath>
            <clipPath id="924a2c8275">
              <path stroke="#fff" d="M38.25 33.406h271.5v271.5H38.25zm0 0" />
            </clipPath>
            <clipPath id="048ecf1008">
              <path
                stroke="#fff"
                d="M174 33.406c-74.973 0-135.75 60.778-135.75 135.75 0 74.973 60.777 135.75 135.75 135.75s135.75-60.777 135.75-135.75c0-74.972-60.777-135.75-135.75-135.75"
              />
            </clipPath>
          </defs>
          <g clipPath="url(#ca46314068)">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M193.215 49.215c39.613 0 75.457 16.347 101.867 42.129 25.781 25.781 42.133 62.254 42.133 101.87 0 39.614-16.352 75.458-42.133 101.868-25.781 25.781-62.254 42.133-101.867 42.133-39.617 0-75.461-16.352-101.871-42.133-25.781-25.781-42.13-62.254-42.13-101.867 0-39.617 16.349-75.461 42.13-101.871 25.781-25.781 62.254-42.13 101.87-42.13zm84.262 59.738c-21.38-21.383-50.934-34.586-84.262-34.586-32.7 0-62.254 13.203-84.262 34.586-21.383 21.379-34.586 50.934-34.586 84.262 0 32.7 13.203 62.254 34.586 84.262 21.379 21.378 50.934 34.586 84.262 34.586 32.7 0 62.254-13.208 84.262-34.586 21.378-21.38 34.586-50.934 34.586-84.262 0-32.7-13.208-62.254-34.586-84.262zm0 0"
            />
          </g>
          <g clipPath="url(#924a2c8275)">
            <g clipPath="url(#048ecf1008)">
              <path
                fill="none"
                stroke="#fff"
                strokeWidth="10.494"
                d="M174 33.406c-74.973 0-135.75 60.778-135.75 135.75 0 74.973 60.777 135.75 135.75 135.75s135.75-60.777 135.75-135.75c0-74.972-60.777-135.75-135.75-135.75"
              />
            </g>
          </g>
        </svg>
      </Box>
    );

    if (isCurrentPageDisableClick) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
