import { Picture } from '../shared';
import { TicketSetting } from '../tickets/ticket-setting';
import { Cart } from './cart';

export interface ProcessOrderDTO {
  paymentIntentId: string;
  cartId: number;
}

export interface TicketResponse {
  tickets: Ticket[];
  orderConfirmationIds: string[];
}

export enum TicketStatus {
  Valid = 'VALID',
  Cancelled = 'CANCELLED',
}
export interface Ticket {
  id: number;
  title?: string;
  slug: string;
  qrCodeId: string;
  validityDate: Date;
  ticketSettingId: number;
  orderId: number;
  ticketSetting: TicketSetting;
  order: Order;
  eventId: number;
  event: Event;
  scannedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  transferredTo?: TransferredTicket;
  user?: { firstname: string; pictures: Picture[]; email: string };
  userId: number;
  status: TicketStatus;
}

export interface Order {
  id: number;
  orderConfirmationId: string;
  amount: number;
  paymentMethodId?: string;
  paymentIntentId?: string;
  cartId: number;
  cart?: Cart;
  tickets?: Ticket[];
  userId: number;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
}

export enum TransferStatus {
  PENDING = 'PENDING',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
}

export interface TransferredTicket {
  id: number;
  ticketId: number;
  ticket?: Ticket;
  recipientEmail: string;
  status: TransferStatus;
  claimedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  user: {
    email: string;
    pictures: Picture[];
    firstname: string;
  };
  userId: number;
}

export interface TransferTicketDTO {
  ticketId: number;
  recipientEmail: string;
}
