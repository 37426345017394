'use client';

import { PropsWithChildren, useEffect } from 'react';
import { isValidToken } from 'src/auth/context/jwt/utils';
import { useAuthContext } from 'src/auth/hooks';
import { useUnreadStore } from 'src/stores/useUnreadStore';
import { Chat, useCreateChatClient } from 'stream-chat-react';
import 'stream-chat-react/dist/css/v2/index.css';

const apiKey: string = 'y7fsjd33c926';

export default function GetStreamProvider({ children }: PropsWithChildren) {
  const { user, chatToken } = useAuthContext();
  const { unreadCount, setUnreadCount } = useUnreadStore();

  const client = useCreateChatClient({
    apiKey,
    tokenOrProvider: chatToken ?? '',
    userData: { id: user?.id?.toString() ?? '' }, // Provide an empty string as a fallback
  });

  useEffect(() => {
    let isFirstLoad = true;

    if (isFirstLoad && !unreadCount && client) {
      setUnreadCount(client?.user?.unread_channels as number);
      isFirstLoad = false; // Mark as no longer the first load
    }
  }, [client, setUnreadCount, unreadCount]);
  if (!client || !user?.id) return children;

  if (chatToken && !isValidToken(chatToken)) {
    return children;
  }

  return (
    <Chat client={client} theme="str-chat__theme-dark">
      {children}
    </Chat>
  );
}
