import { QueryClient } from '@tanstack/react-query';

export const DEFAULT_CACHE_DURATION = 1000 * 60 * 60 * 24; // 24 hours
export const DEFAULT_STALE_DURATION = 1000 * 60 * 2; // 2 minutes

export default new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: DEFAULT_CACHE_DURATION,
      staleTime: DEFAULT_STALE_DURATION,
    },
  },
});
