'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  AppBar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  Slide,
  Toolbar,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/nextjs';
import { m } from 'framer-motion';
import { useRouter } from 'next/navigation';
import { TransitionProps } from 'notistack';
import { useQueryState } from 'nuqs';
import posthog from 'posthog-js';
import { forwardRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'src/auth/hooks';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import IntercomSupport from 'src/components/intercom/intercom';
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
import { useTranslate } from 'src/locales';
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
import * as Yup from 'yup';

import SocialLogin from './social-login/social-login';
import SplashScreen from './splash-view';

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
  ) => <Slide direction="up" ref={ref} {...props} />
);

export default function LoginViewModal() {
  const { login } = useAuthContext();
  const mdUp = useResponsive('up', 'md');
  const [errorMsg, setErrorMsg] = useState('');
  const [isOpen, setOpen] = useQueryState('login');
  const [showSplash, setShowSplash] = useState(true);
  const password = useBoolean();
  const router = useRouter();
  const { t: tConnect } = useTranslate('connect');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await login?.(data.email, data.password);
      Sentry.setUser({ email: data.email });
      posthog.identify(data.email);
      setOpen(null);
    } catch (error) {
      console.error(error);
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const onHandleClose = () => {
    setOpen(null);
    setShowSplash(true);
  };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">{tConnect('auth.signInToTrybe')}</Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2">{tConnect('auth.newUser')}</Typography>

        <Link component={RouterLink} href={paths.auth.verifyPhone} variant="subtitle2">
          {tConnect('auth.createAccount')}
        </Link>
      </Stack>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField name="email" label={tConnect('auth.emailAddress')} />

      <RHFTextField
        name="password"
        label={tConnect('auth.password')}
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Link
        href={paths.auth.forgotPassword}
        variant="body2"
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        {tConnect('auth.forgotPassword')}
      </Link>

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {tConnect('auth.login')}
      </LoadingButton>
      <SocialLogin />
    </Stack>
  );

  return mdUp ? (
    <Drawer
      anchor="right"
      open={isOpen === 'open'}
      onClose={onHandleClose}
      sx={{
        '& .MuiDrawer-paper': {
          boxShadow: 'none',
          width: '30vw',
          height: '100%',
        },
      }}
    >
      <m.div
        key="main-section-1"
        initial={{ width: '30vw' }}
        animate={{
          width: showSplash ? '30vw' : '0vw',
        }}
        exit={{ width: '0vw' }}
        transition={{ duration: 0.5 }}
      >
        <Collapse in={showSplash} orientation="horizontal">
          <Box sx={{ maxWidth: '30vw' }}>
            <SplashScreen />
            <DialogActions
              sx={{
                justifyContent: 'center',
                gap: 2,
                position: 'fixed',
                bottom: 0,
                width: '100%',
                zIndex: 1000,
                ...(mdUp && {
                  maxWidth: '30vw',
                }),
              }}
            >
              <Button
                sx={{ flexGrow: 0.5 }}
                variant="outlined"
                size="large"
                onClick={() => {
                  router.replace('/verify-phone');
                }}
              >
                {tConnect('auth.signUp')}
              </Button>
              <Button
                sx={{ flexGrow: 0.5 }}
                variant="contained"
                size="large"
                onClick={() => setShowSplash(false)}
              >
                {tConnect('auth.signIn')}
              </Button>
            </DialogActions>
          </Box>
        </Collapse>
      </m.div>
      <m.div
        key="main-section"
        initial={{ width: '0vw' }}
        animate={{
          width: !showSplash ? '100vw' : '0vw',
        }}
        exit={{ width: '100vw' }}
        transition={{ duration: 0.5 }}
      >
        <Collapse in={!showSplash} orientation="horizontal">
          <DialogContent sx={{ p: 2, position: 'absolute', width: '100%', top: '7%' }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderHead}
              {renderForm}
            </FormProvider>
          </DialogContent>
        </Collapse>
      </m.div>
    </Drawer>
  ) : (
    <Dialog
      fullScreen
      open={isOpen === 'open'}
      onClose={onHandleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IntercomSupport />
          <Button variant="outlined" onClick={onHandleClose}>
            {tConnect('auth.close')}
          </Button>
        </Toolbar>
      </AppBar>
      <m.div
        key="main-section-1"
        initial={{ width: '100vw' }}
        animate={{
          width: showSplash ? '100vw' : '0vw',
        }}
        exit={{ width: '0vw' }}
        transition={{ duration: 0.5 }}
      >
        <Collapse in={showSplash} orientation="horizontal">
          <Box sx={{ maxWidth: '100vw' }}>
            <SplashScreen />
          </Box>
          <DialogActions
            sx={{
              justifyContent: 'center',
              gap: 2,
              position: 'fixed',
              bottom: 0,
              width: '100%',
              zIndex: 1000,
            }}
          >
            <Button
              sx={{ flexGrow: 0.5 }}
              variant="outlined"
              size="large"
              onClick={() => {
                router.replace('/verify-phone');
              }}
            >
              {tConnect('auth.signUp')}
            </Button>
            <Button
              sx={{ flexGrow: 0.5 }}
              variant="contained"
              size="large"
              onClick={() => setShowSplash(false)}
            >
              {tConnect('auth.signIn')}
            </Button>
          </DialogActions>
        </Collapse>
      </m.div>
      <m.div
        key="main-section"
        initial={{ width: '0vw' }}
        animate={{
          width: !showSplash ? '100vw' : '0vw',
        }}
        exit={{ width: '100vw' }}
        transition={{ duration: 0.5 }}
      >
        <Collapse in={!showSplash} orientation="horizontal">
          <DialogContent sx={{ p: 2, position: 'absolute', width: '100%', top: '7%' }}>
            <FormProvider methods={methods} onSubmit={onSubmit}>
              {renderHead}
              {renderForm}
            </FormProvider>
          </DialogContent>
        </Collapse>
      </m.div>
    </Dialog>
  );
}
