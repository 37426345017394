'use client';

import { Button } from '@mui/material';
import React from 'react';
import { useTranslate } from 'src/locales';

type IntercomSupportProps = {
  link?: boolean;
};

export default function IntercomSupport({ link }: IntercomSupportProps) {
  const { t } = useTranslate('commons');

  return (
    <Button key="intercom" href="/support">
      {link ? t('support.support') : t('support.need-support')}
    </Button>
  );
}
