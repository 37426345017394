import { ShopItem, ShopItemEntityType } from '../events/shop';
import { RefundPolicy } from '../refund-policy';
import { Picture } from '../shared';
import { TicketSetting } from '../tickets/ticket-setting';
import { PromoCode } from './promocode';

export interface CartItemDTO {
  id: number;
  title: string;
  slug: string;
  description?: string;
  maxPerOrder?: number;
  from?: Date;
  to?: Date;
  quantity: number;
  quantityLeft: number;
  fees: number;
  net: number;
  totalPrice: number;
  bundleSize: number;
  refundPolicy: RefundPolicy;
  maxPerUser?: number;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  quantityInCart: number;
}

export interface CartShopItemDTO {
  id: number;
  entityId: number;
  entityType: ShopItemEntityType;
  title: string;
  net: number;
  price: number;
  description?: string;
  maxPerOrder?: number;
  maxPerUser?: number;
  isVisible: boolean;
  quantity: number;
  quantityLeft: number;
  fees: number;
  totalPrice: number;
  picture?: Picture;
  eventId: number;
  createdAt: Date;
  updatedAt: Date;
  refundPolicy: RefundPolicy;
  refundDaysLimit?: number;
  deletedAt?: Date;
  quantityInCart: number;
}

export interface CreateCartDTO {
  items: TicketItemDTO[];
  promoCodeId?: number;
}

export interface ShopItemDTO {
  shopItemId: number;
  quantity: number;
}

export interface TicketItemDTO {
  ticketSettingId: number;
  quantity: number;
}

export interface CartDTO {
  items?: TicketItemDTO[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
}

export interface CartItem {
  id: number;
  quantity: number;
  price: number;
  ticketSettingId: number;
  shopItemId?: number;
  shopItem: ShopItem;
  createdAt: Date;
  updatedAt: Date;
  ticketSetting: TicketSetting;
}

export enum CartStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED',
}

export interface Cart {
  id: number;
  eventId: number;
  userId: number;
  orderId?: number;
  status: CartStatus;
  total: number;
  paymentIntentId: string;
  subtotal: number;
  items: CartItem[];
  shopItems?: ShopItemDTO[];
  promoCodeId?: number;
  promoCode?: PromoCode;
  createdAt: Date;
  updatedAt: Date;
  fees: number;
  discount: number;
}

export interface CartResponse extends Cart {
  client_secret?: string;
}
